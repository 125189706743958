
import { defineComponent } from "vue";
import { MatchCode, MatchStatus, GetMatch } from '@/utils/_Constants';
import Live from "@/components/base/LiveLabel.vue";
import DateTimeLabel from "@/components/base/DateTimeLabel.vue";
import LiveTimeBar from "@/components/base/LiveTimeBar.vue";
import VersusContestants from "@/components/base/VersusContestants.vue";

export default defineComponent({
  name: "Match",
  components: { Live, DateTimeLabel, LiveTimeBar, VersusContestants },
  props: {
    game: {
      type: Object,
      required: true
    },
    items: String,
    theme: String,
  },
  data() {
    return {
      MatchCode,
      MatchStatus,
      GetMatch
    };
  },
  computed: {
    substitutes() {
      if (!this.game['substitutes'])
        return false

      const subsOut = Object.fromEntries(Object.entries(this.game['substitutes']).filter(([key]) => key.includes('in')));
      return subsOut
    },
    isActive() {
      if (this.game[GetMatch.MATCH_STATUS] != MatchStatus.END && this.game[GetMatch.MATCH_STATUS] != MatchStatus.NOT_STARTED)
        return true
      else
        return false
    },
    isNotStarted() {
      if (this.game[GetMatch.MATCH_STATUS] == MatchStatus.NOT_STARTED)
        return true
      else
        return false
    },
    isEnded() {
      if (this.game[GetMatch.MATCH_STATUS] == MatchStatus.END)
        return true
      else
        return false
    },
    selectedGame() {
      return {
        matchId: this.game[GetMatch.MATCH_ID],
        homeId: this.game[GetMatch.CONTESTANT_HOME_ID],
        awayId: this.game[GetMatch.CONTESTANT_AWAY_ID],
        matchStatus: this.game[GetMatch.MATCH_STATUS],
      }
    },
    homeLogo() {
        return this.game[GetMatch.CONTESTANT_AWAY_LOGOS][GetMatch.LOGO]
    },
    awayLogo() {
        return this.game[GetMatch.CONTESTANT_AWAY_LOGOS][GetMatch.LOGO]
    },
    ctaText() {
      if(this.theme != 'be'){
        switch(this.game[GetMatch.MATCH_STATUS]){
          case MatchStatus.NOT_STARTED:
            return 'kies je opstelling'
          case MatchStatus.END:
            return 'kies je speler'
          default:
            return 'kies je wisselspelers'
        }
      }else{
        switch(this.game[GetMatch.MATCH_STATUS]){
          case MatchStatus.NOT_STARTED:
            return 'Maak jouw opstelling'
          case MatchStatus.END:
            return 'Kies je man van de match'
          default:
            return 'Kies je wisselspeler'
        }
      }
    },
  },
});
