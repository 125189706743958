export enum PageName {
  HUB_BELGIUM = "HubBelgium",
  HUB_NETHERLANDS = "HubNetherlands",
  WIDGET = "Widget",
  MATCH_REDIRECT = "MatchRedirect",
}

export enum GetMatch {
  MATCH_ID = "match_id",
  MATCH_STATUS = "match_status",
  MATCH_STATUS_CODE = "match_status_code",
  MATCH_TIME = "match_time",
  MATCH_DATE = "match_date",
  MATCH_END_TIME = "match_end_time",
  MATCH_PLAY_TIME = "match_play_time",
  HOME_GOALS = "home_goals",
  AWAY_GOALS = "away_goals",
  COMPETITION_ID = "competition_id",
  COMPETITION_NAME = "competition_name",
  CONTESTANT_HOME_ID = "contestant_home_id",
  CONTESTANT_AWAY_ID = "contestant_away_id",
  VENUE_NAME = "venue_name",
  CONTESTANT_HOME_NAME = "contestant_home_name",
  CONTESTANT_HOME_LOGOS = "contestant_home_logos",
  CONTESTANT_AWAY_NAME = "contestant_away_name",
  CONTESTANT_AWAY_LOGOS = "contestant_away_logos",
  LOGO = "logo",
  LOGO_SMALL = "logo_small",
  NETHERLANDS_ID = "411",
}

export enum MatchStatus {
  NOT_STARTED = "Not started",
  FIRST_HALF = "1st Half",
  HALF_TIME = "HalfTime",
  SECOND_HALF = "2nd Half",
  NINETY_MINUTES = "90 mins",
  EXTRA_TIME = "1st Extra Time",
  HUNDREDFIVE_MINUTES = "105 mins",
  SECOND_EXTRA_TIME = "2nd Extra Time",
  HUNDREDTWENTY_MINUTES = "120 mins",
  PENALTY_SHOOTOUT = "Penalty Shootout",
  END = "End"
}

export enum MatchCode {
  LINE_UP = "Line-up",
  NOT_STARTED = "Not Started",
  IN_PROGRESS = "In Progress",
  INTERVAL = "Interval",
  SUSPENDED = "Suspended",
  FINISHED = "Finished",
  UNOFFICIAL_RESULT = "Unofficial result",
  NO_LIVE = "No Live Updates (ISS Internal code)",
  ABANDONED = "Abandoned",
  POSTPONED = "Postponed",
  DELAYED = "Delayed"
}

export enum GetTools {
  MAN_OF_THE_MATCH = "spelervandewedstrijd",
  FORMATION_SETUP = "deopstelling",
  SUBSTITUTE_PLAYER = "wisselspeler"
}