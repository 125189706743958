
import { defineComponent } from "vue";

export default defineComponent({
  name: "DateTimeLabel",
  props: {
    date: {
      type: String,
      required: true
    },
    time: {
      type: String,
      required: true
    },
  },
  methods: {

    dateFormat(date: string) {
      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']
      return date.substring(8, 11) + ' ' + months[parseInt(date.substring(5, 7)) - 1]
    },
  },
  data() {
    return {};
  },
});
