
import { defineComponent } from "vue";
import Button from "@/components/Button.vue"

export default defineComponent({
  name: "PopupMessage",
  components: { Button },
  props: {
    title: {
      required: true,
      type: String
    },
    description: String
  },
  data() {
    return {};
  },
  methods: {
  }
});
