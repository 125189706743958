import { PageName } from '@/utils/_Constants';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import HubNetherlands from '../views/HubNetherlands.vue';
import HubBelgium from '../views/HubBelgium.vue';
import WidgetRedirector from '../views/WidgetRedirector.vue';
import MatchRedirect from '../views/MatchRedirect.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: PageName.HUB_NETHERLANDS,
    component: HubNetherlands
  },
  {
    path: '/be',
    name: PageName.HUB_BELGIUM,
    component: HubBelgium
  },
  {
    path: '/widget',
    name: PageName.WIDGET,
    component: WidgetRedirector
  },
  {
    path: '/match',
    name: PageName.MATCH_REDIRECT,
    component: MatchRedirect
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router;
