
import { defineComponent } from "vue";

export default defineComponent({
  name: "VersusContestants",
  props: {
    homeName: {
      type: String,
      required: true
    },
    homeLogo: {
      type: String,
      required: false
    },
    awayName: {
      type: String,
      required: true
    },
    awayLogo: {
      type: String,
      required: false
    },
  },
  data() {
    return {};
  },
});
