import { defineStore } from 'pinia'

export const useUserStore = defineStore('user', {
  state: () => {
    return {
      user:{
        id: '',
        brand: '',
      }
    }
  },
  actions: {
    // Setting of user data
    setUser(id: string, brand: string){
      this.user.id = id;
      this.user.brand = brand;
    }
  },
  getters: {
    // Getting of user data
    getUserId: (state) => state.user.id,
    getUserBrand: (state) => state.user.brand,
    getUserData: (state) => state.user,
  }  
})