
import { defineComponent } from "vue";
import { useUserStore } from "@/store/_UserStore";
import axios from 'axios';

export default defineComponent({
  name: "App",
  data() {
    return {
      userId: null,
    };
  },
  created() {
    this.checkParams()
  },
  methods: {
    async checkParams() {
      try {
        await this.$router.isReady()
        this.setDpgData();
        // onSuccess
      } catch (err) {
        // onError
        console.log(err)
      }
    },
    setDpgData() {
      const user = useUserStore();
      const param = this.$route.query;
      if (param.userId || param.brand) {
        user.setUser(param.userId as string || '', param.brand as string)
      }
    },
  },
})
