import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e80e09da"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "timer bar" }
const _hoisted_2 = { class: "match-progress left" }
const _hoisted_3 = {
  key: 0,
  class: "marker"
}
const _hoisted_4 = { class: "counter" }
const _hoisted_5 = {
  key: 0,
  class: "break"
}
const _hoisted_6 = { class: "match-progress right" }
const _hoisted_7 = { class: "marker" }
const _hoisted_8 = { class: "counter" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "minutes played",
        style: _normalizeStyle(`width: ${(_ctx.playTime * 100) / 45}%;`)
      }, [
        (!_ctx.isBreak)
          ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, _toDisplayString(Math.floor(_ctx.playTime)) + "'", 1)
            ], 512)), [
              [_vShow, _ctx.playTime > 0 && _ctx.playTime <= 45]
            ])
          : _createCommentVNode("", true)
      ], 4)
    ]),
    (_ctx.isBreak)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, "Rust"))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", {
        class: "minutes played",
        style: _normalizeStyle(_ctx.playTime >= 46 ? `width: ${((_ctx.playTime - 45) * 100) / 45}%;` : `width: 0%;`)
      }, [
        _withDirectives(_createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, _toDisplayString(Math.floor(_ctx.playTime)) + "'", 1)
        ], 512), [
          [_vShow, _ctx.playTime > 45]
        ])
      ], 4)
    ])
  ]))
}