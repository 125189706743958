
import { defineComponent } from "vue";
import api from "@/modules/Api";
import { MatchCode, MatchStatus, GetMatch } from '@/utils/_Constants';
export default defineComponent({
  name: "Hub",
  data() {
    return {
      games: [],
      tools: {
        wisselspeler: [],
        spelervandewedstrijd: [],
        deopstelling: [],
      },
    };
  },
  created() {
    this.matchData();
  },
  methods: {
    async matchData() {
      const country = this.$route.query.country as string || 'netherlands'
      const brand = this.$route.query.brand as string || (country == 'netherlands' ? 'ad' : 'hln')
      this.games = await api.getMatches(country, brand);
      if (!this.games) return;
      this.games.forEach((game) => {
        if (game[GetMatch.MATCH_STATUS] == MatchStatus.NOT_STARTED) this.tools.deopstelling.push(game);
        else if (game[GetMatch.MATCH_STATUS] != MatchStatus.NOT_STARTED && game[GetMatch.MATCH_STATUS] != MatchStatus.END)
          this.tools.wisselspeler.push(game);
        else this.tools.spelervandewedstrijd.push(game);
      });

      //Redirect to tools depending on active games
      if (this.tools.wisselspeler.length >= 1) {
        const matchId = this.tools.wisselspeler[0]['match_id'];
        window.open(`${process.env.VUE_APP_URL_WISSEL}/widget?matchId=${matchId}&brand=${brand}&country=${country}`, '_self')
      } else if (this.tools.spelervandewedstrijd.length >= 1) {
        const matchId = this.tools.spelervandewedstrijd[0]['match_id'];
        window.open(`${process.env.VUE_APP_URL_WISSEL}/widget/svdw?matchId=${matchId}&brand=${brand}&country=${country}`, '_self')
      } else {
        const matchId = this.tools.deopstelling[0]['match_id'];
        window.open(`${process.env.VUE_APP_URL_OPSTELLING}/widget?matchId=${matchId}&brand=${brand}&country=${country}`, '_self')
      }
    },
  },
});
