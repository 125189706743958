
import { defineComponent } from "vue";
import { MatchStatus, GetMatch, GetTools } from '@/utils/_Constants';
import eredivisie from "@/assets/data/translations/eredivisie.json";
import oranje from "@/assets/data/translations/oranje.json";
import PopupMessage from "@/components/PopupMessage.vue";
import Contestants from "@/components/Contestants.vue";
import { useUserStore } from "@/store/_UserStore";
import { IMatch } from '@/interfaces/_ICore';
import Splash from "@/components/Splash.vue";
import Match from "@/components/Match.vue";
import api from "@/modules/Api";
export default defineComponent({
  name: "Hub",
  data() {
    return {
      games: [],
      clickedMatch: null as any,
      matchSelected: false,
      showMessage: false,
      splashActive: true,
      toolName: "",
      contestantsPopupTitle: "",
      messageDescription: "",
      tools: {
        substitutePlayer: [],
        manOfTheMatch: [],
        formationSetup: [],
      },
      copy: oranje,
      copyEredivisie: eredivisie,
      copyOranje: oranje,
    };
  },
  components: { Splash, Match, Contestants, PopupMessage },
  created() {
    this.matchData();
    this.splashScreen();
  },
  methods: {
    async matchData() {
      const brand = this.$route.query.brand as string || 'ad'
      const country = process.env.VUE_APP_APP_ENV !== 'production' && this.$route.query.country ?
          this.$route.query.country as string : 'netherlands';

      this.games = await api.getMatches(country, brand);
      if (!this.games) return;

      // Put each game into required tool
      this.games.forEach((game) => {
        if (game[GetMatch.MATCH_STATUS] == MatchStatus.NOT_STARTED)
          this.tools.formationSetup.push(game);

        else if (game[GetMatch.MATCH_STATUS] != MatchStatus.NOT_STARTED && game[GetMatch.MATCH_STATUS] != MatchStatus.END)
          this.tools.substitutePlayer.push(game);

        else this.tools.manOfTheMatch.push(game);

        //Change copy if Netherlands is playing
        if (game[GetMatch.CONTESTANT_HOME_ID] == GetMatch.NETHERLANDS_ID || game[GetMatch.CONTESTANT_AWAY_ID] == GetMatch.NETHERLANDS_ID)
          this.copy = this.copyOranje;
      });
    },
    async splashScreen() {
      //Show Splash screen
      await this.delayTime(2);
      this.splashActive = false;
    },
    async informationPopup(type: string) {
      //Adjust Copy description to selected tool's description
      this.showMessage = true;
      switch (type) {
        case GetTools.FORMATION_SETUP:
          this.messageDescription = this.copy[type].help;
          this.toolName = type;
          break;
        case GetTools.SUBSTITUTE_PLAYER:
          this.messageDescription = this.copy[type].help;
          this.toolName = type;
          break;
        case GetTools.MAN_OF_THE_MATCH:
          this.messageDescription = this.copy[type].help;
          this.toolName = type;
          break;
        default:
          this.toolName = type;
      }
    },
    async delayTime(time: number) {
      const delay = (ms: any) => new Promise((res) => setTimeout(res, ms));
      await delay(time * 1000);
    },
    dpgToolRedirect(match: IMatch) {
      const matchId = match.matchId;
      const contestantId = match.contestantId;
      const status = match.status;

      //Get user data
      const userStore = useUserStore();
      const userId = userStore.getUserId
      const brand = userStore.getUserBrand || 'ad'
      const country = 'netherlands'

      switch (status) {
        case MatchStatus.NOT_STARTED:
          window.location.href =`${process.env.VUE_APP_URL_OPSTELLING}/pick-formation/${contestantId}/${matchId}/?hub=true&brand=${brand}&userId=${userId}&country=${country}`
          // window.open(`${process.env.VUE_APP_URL_OPSTELLING}/pick-formation/${contestantId}/${matchId}/?hub=true&brand=${brand}&userId=${userId}&country=${country}`, '_self')
          break;
        case MatchStatus.END:
          window.location.href =`${process.env.VUE_APP_URL_SVDW}#match?match=${matchId}&brand=${brand}&userId=${userId}&country=${country}`
          // window.open(`${process.env.VUE_APP_URL_SVDW}#match?match=${matchId}&brand=${brand}&userId=${userId}&country=${country}`, '_self');
          break
        default:
          window.location.href =`${process.env.VUE_APP_URL_WISSEL}/match/${matchId}/?isAway=${!match.isHome}&hub=true&brand=${brand}&userId=${userId}&country=${country}`
          // window.open(`${process.env.VUE_APP_URL_WISSEL}/match/${matchId}/?isAway=${!match.isHome}&hub=true&brand=${brand}&userId=${userId}&country=${country}`, '_self')
          break;
      }
    },
    contestantsPopup(match: any, type: string, title: string) {
      //Get user data
      const userStore = useUserStore();
      const userId = userStore.getUserId;
      const brand = userStore.getUserBrand;
      const country = 'netherlands'

      //Selection menu for MOTM is in tool itself
      if (type.toLocaleLowerCase().replace(/\s/g, '') == 'manOfTheMatch') {
        window.open(`${process.env.VUE_APP_URL_SVDW}#match?match=${match.matchId}&brand=${brand}&userId=${userId}&country=${country}`, '_self');
        return;
      }

      // Check if netherlands is playing
      if (match.homeId == GetMatch.NETHERLANDS_ID || match.awayId == GetMatch.NETHERLANDS_ID) {
        this.nationalTeamRedirect(match)
        return
      }

      // Set pop up data
      this.contestantsPopupTitle = title;
      this.toolName = type.replace(/\s/g, '').toLocaleLowerCase();
      this.matchSelected = true;
      this.clickedMatch = this.games.find((game) => game[GetMatch.MATCH_ID] === match.matchId);
    },
    nationalTeamRedirect(match: any) {
      //Get and Set user data
      const userStore = useUserStore();
      const userId = userStore.getUserId;
      const brand = userStore.getUserBrand || 'ad';
      const country = 'netherlands'
      this.matchSelected = true;
      //For Netherlands games set isAway to nationteam's away status
      const isAway = match.awayId == GetMatch.NETHERLANDS_ID;

      //Redirect user to selected tool
      switch (match.matchStatus) {
        case MatchStatus.NOT_STARTED:
          window.open(`${process.env.VUE_APP_URL_OPSTELLING}/pick-formation/${GetMatch.NETHERLANDS_ID}/${match.matchId}/?hub=true&brand=${brand}&userId=${userId}&country=${country}`, '_self')
          break;
        case MatchStatus.END:
          window.open(`${process.env.VUE_APP_URL_SVDW}#match?match=${match.matchId}&brand=${brand}&userId=${userId}&country=${country}`, '_self');
          break
        default:
          window.open(`${process.env.VUE_APP_URL_WISSEL}/match/${match.matchId}/?isAway=${isAway}&hub=true&brand=${brand}&userId=${userId}&country=${country}`, '_self')
          break;
      }
    },
    closeContestants() {
      this.matchSelected = false;
    },
    closeMessage() {
      this.showMessage = !this.showMessage;
    },
  },
  computed: {
    contestantPopupTitle() {
      return [
        this.copy.wisselspeler.confrim,
        this.copy.spelervandewedstrijd.confrim,
        this.copy.deopstelling.confrim
      ]
    }
  }
});
