
import { defineComponent } from "vue";
import { Vue3Lottie } from 'vue3-lottie'
import 'vue3-lottie/dist/style.css'
import logoLottie from '@/assets/images/lottie/logo.json'

export default defineComponent({
  name: "Splash",
  props: {
    theme: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      logoLottie,
    };
  },
  components: { Vue3Lottie },
  methods: {}
});
