
import { defineComponent } from "vue";

export default defineComponent({
  name: "LiveTimeBar",
  props: {
    playTime: {
      type: Number,
      required: true
    },
    isBreak: {
      type: Boolean,
      required: false
    },
  },

  data() {
    return {};
  },
});
