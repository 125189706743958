import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-277f7099"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "top"
}
const _hoisted_2 = {
  key: 1,
  class: "bottom"
}
const _hoisted_3 = {
  key: 2,
  class: "cta"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Live = _resolveComponent("Live")!
  const _component_DateTimeLabel = _resolveComponent("DateTimeLabel")!
  const _component_VersusContestants = _resolveComponent("VersusContestants")!
  const _component_LiveTimeBar = _resolveComponent("LiveTimeBar")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["item", {ended: _ctx.isEnded, belgium: _ctx.theme == 'be', active: _ctx.isActive}]),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('pickContestant', _ctx.selectedGame)))
  }, [
    (!_ctx.isEnded)
      ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
          _createVNode(_component_Live, { isActive: _ctx.isActive }, null, 8, ["isActive"]),
          (_ctx.isNotStarted)
            ? (_openBlock(), _createBlock(_component_DateTimeLabel, {
                key: 0,
                date: _ctx.game[_ctx.GetMatch.MATCH_DATE],
                time: _ctx.game[_ctx.GetMatch.MATCH_TIME]
              }, null, 8, ["date", "time"]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("section", {
      class: _normalizeClass(["center", { inactive: !_ctx.isActive, ended: _ctx.isEnded }])
    }, [
      _createVNode(_component_VersusContestants, {
        homeName: _ctx.game[_ctx.GetMatch.CONTESTANT_HOME_NAME],
        homeLogo: _ctx.game[_ctx.GetMatch.CONTESTANT_HOME_LOGOS][_ctx.GetMatch.LOGO],
        awayName: _ctx.game[_ctx.GetMatch.CONTESTANT_AWAY_NAME],
        awayLogo: _ctx.awayLogo
      }, null, 8, ["homeName", "homeLogo", "awayName", "awayLogo"])
    ], 2),
    (_ctx.isActive)
      ? (_openBlock(), _createElementBlock("section", _hoisted_2, [
          _createVNode(_component_LiveTimeBar, {
            playTime: _ctx.game[_ctx.GetMatch.MATCH_PLAY_TIME],
            "is-break": _ctx.game[_ctx.GetMatch.MATCH_STATUS] == _ctx.MatchStatus.HALF_TIME ? true : false
          }, null, 8, ["playTime", "is-break"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.theme == 'be')
      ? (_openBlock(), _createElementBlock("section", _hoisted_3, _toDisplayString(_ctx.ctaText), 1))
      : _createCommentVNode("", true)
  ], 2))
}