
import { defineComponent } from "vue";
import api from "@/modules/Api";
import { MatchCode, MatchStatus, GetMatch } from '@/utils/_Constants';
export default defineComponent({
  name: "Hub",
  data() {
    return {
      games: [],
      tools: {
        wisselspeler: [] as any,
        spelervandewedstrijd: [] as any,
        deopstelling: [] as any,
      },
    };
  },
  created() {
    this.matchData();
  },
  methods: {
    async matchData() {
      let matchId = this.$route.query.matchId;
      const country = this.$route.query.country as string || 'netherlands';
      const brand = this.$route.query.brand as string || (country == 'netherlands' ? 'ad' : 'hln')
      console.log(country, brand)
      this.games = await api.getMatches(country, brand);
      console.log(this.games)
      console.log(matchId)
      let matchIdGame = null as any
      if (!this.games) return;
      this.games.forEach((game: any) => {
        console.log(game)
        if(game?.match_id == matchId) matchIdGame = game
      });
      console.log(this.games)
      
      this.dpgToolRedirect(matchIdGame || matchId)
    },
    dpgToolRedirect(match: any) {
      const matchId = this.$route.query.matchId;
      const status = match?.match_status || "End";
      //Get user data
      const brand = this.$route.query.brand as string || 'ad';
      const country = this.$route.query.country as string || 'netherlands';
        console.log(match)
      switch (status) {
        case MatchStatus.NOT_STARTED:
          window.open(`${process.env.VUE_APP_URL_OPSTELLING}/widget?matchId=${matchId}&brand=${brand}&country=${country}`, '_self')
          break;
        case MatchStatus.END:
          window.open(`${process.env.VUE_APP_URL_WISSEL}/widget/svdw?matchId=${matchId}&brand=${brand}&country=${country}`, '_self')
          break
        default:
          window.open(`${process.env.VUE_APP_URL_WISSEL}/widget?matchId=${matchId}&brand=${brand}&country=${country}`, '_self')
          break;
      }
    },
  },
});
