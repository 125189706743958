import axios, { AxiosResponse } from "axios";

class APIModule {
  constructor() { }

  async getMatches(country: string, brand: string) {
    const result = await axios(`${process.env.VUE_APP_API_URL}/main/getMatches?country=${country}&brand=${brand}`, {
    }).catch((error) => {
      return
    });
    return result?.data?.data
  }

  async andgageRegister(name: string) {
    const result = await axios(`https://api.andgage.io/participant/client/register`, {
      params: {
        accountId: 2689,
        entityId: 0,
        entityId2: 0,
        entityType: 1,
        firstname: name,
        token: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhY2NvdW50SWQiOiIyNjg5IiwidHlwZSI6InB1YmxpYyJ9.5mr2xik3VENG42alb6aUnvq7oMnsyZ-PQxXEM1XlhPk'
      }
    }).catch((error) => {
      return
    });
    return result?.data?.data?.token
  }

  async andgagePost(text: string, token: string) {
    const result = await axios(`https://api.andgage.io/messaging/client/postMessage`, {
      params: {
        entityId: 0,
        entityId2: 0,
        entityType: 1,
        text,
        token,
        type: "QA"
      }
    }).catch((error) => {
      return false
    });
    return true
  }
}

const apiModule = new APIModule();
export default apiModule;
