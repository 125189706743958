
import { defineComponent } from "vue";

export default defineComponent({
  name: "LiveLabel",
  props: {
    isActive: {
      type: Boolean,
      required: true
    },
  },

  data() {
    return {};
  },
});
