
import { defineComponent } from "vue";
import { MatchCode, MatchStatus, GetMatch } from '@/utils/_Constants';

export default defineComponent({
  name: "PopupConfirm",
  props: {
    game: {
      type: Object,
      required: true
    },
    toolName: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      MatchCode,
      MatchStatus,
      GetMatch
    };
  },
  methods: {
  }
});
