
import { defineComponent } from "vue";

export default defineComponent({
  name: "Button",
  props: {
    btnText: String,
    btnType: String
  },

  data() {
    return {};
  },
});
