
import { defineComponent } from "vue";
import api from "@/modules/Api";
import Splash from "@/components/Splash.vue";
import Match from "@/components/Match.vue";
import Contestants from "@/components/Contestants.vue";
import PopupMessage from "@/components/PopupMessage.vue";
import copyBelgium from "@/assets/data/translations/belgium.json";
import { IMatch } from '@/interfaces/_ICore';
import { useUserStore } from "@/store/_UserStore";
import { MatchCode, MatchStatus, GetMatch } from '@/utils/_Constants';

export default defineComponent({
  name: "HubNetherlands",
  data() {
    return {
      games: [],
      clickedMatch: null as any,
      splashActive: true,
      timeStartCompare: 0,
      itemClicked: false,
      matchSelected: false,
      showMessage: false,
      toolName: "",
      contestantsPopupTitle: "",
      messageDescription: "",
      tools: {
        jouwwisselspeler: [],
        jouwmanofthematch: [],
        jouwopstelling: [],
      },
      toolsNames: [
        "Jouw wisselspeler",
        "Jouw Man of the match",
        "Jouw Opstelling"
      ],
      copy: copyBelgium,
    };
  },
  components: { Splash, Match, Contestants, PopupMessage },
  created() {
    this.matchData();
    this.splashScreen();
  },
  methods: {
    async matchData() {
      const brand = this.$route.query.brand as string || 'hln'
      this.games = await api.getMatches('belgium', brand);
      if (!this.games) return;
      this.games.forEach((game) => {
        if (game[GetMatch.MATCH_STATUS] == MatchStatus.NOT_STARTED) this.tools.jouwopstelling.push(game);
        else if (game[GetMatch.MATCH_STATUS] != MatchStatus.NOT_STARTED && game[GetMatch.MATCH_STATUS] != MatchStatus.END)
          this.tools.jouwwisselspeler.push(game);
        else this.tools.jouwmanofthematch.push(game);
      });
    },
    async splashScreen() {
      this.timeStartCompare = Date.now();
      await this.delayTime(2);
      this.splashActive = false;
    },
    async informationPopup(type: string) {
      this.showMessage = true;
      switch (type) {
        case 'jouwopstelling':
          this.messageDescription = this.copy[type].help;
          this.toolName = type;
          break;
        case 'jouwwisselspeler':
          this.messageDescription = this.copy[type].help;
          this.toolName = type;
          break;
        case 'jouwmanofthematch':
          this.messageDescription = this.copy[type].help;
          this.toolName = type;
          break;
        default:
          this.toolName = type;
      }
    },
    async delayTime(time: number) {
      this.timeStartCompare = this.timeStartCompare + time * 1000;
      const delay = (ms: any) => new Promise((res) => setTimeout(res, ms));
      await delay(time * 1000);
    },
    buttonTerms() {
      window.open("/terms.pdf");
    },
    dpgToolRedirect(match: any) {
      const matchId = match.matchId;
      const contestantId = match.awayId == '3004' ? match.awayId : match.homeId;
      const isAway = match.awayId == '3004' ? true : false;
      const status = match.matchStatus;

      const userStore = useUserStore();
      //Get user data
      const userId = userStore.getUserId
      const brand = 'hln'

      this.matchSelected = true;
      switch (status) {
        case MatchStatus.NOT_STARTED:
          window.location.href =`${process.env.VUE_APP_URL_OPSTELLING}/pick-formation/${contestantId}/${matchId}/?hub=true&brand=${brand}&userId=${userId}&country=belgium`
          // window.open(`${process.env.VUE_APP_URL_OPSTELLING}/pick-formation/${contestantId}/${matchId}/?hub=true&brand=${brand}&userId=${userId}&country=belgium`, '_self')
          break;
        case MatchStatus.END:
          window.location.href =`${process.env.VUE_APP_URL_SVDW_BE}#match?match=${matchId}&brand=${brand}&userId=${userId}&country=belgium`
          // window.open(`${process.env.VUE_APP_URL_SVDW_BE}#match?match=${matchId}&brand=${brand}&userId=${userId}&country=belgium`, '_self');
          break
        default:
          window.location.href =`${process.env.VUE_APP_URL_WISSEL}/match/${matchId}/?isAway=${isAway}&hub=true&brand=${brand}&userId=${userId}&country=belgium`
          // window.open(`${process.env.VUE_APP_URL_WISSEL}/match/${matchId}/?isAway=${isAway}&hub=true&brand=${brand}&userId=${userId}&country=belgium`, '_self')
          break;
      }
    },
    contestantsPopup(match: any, type: string, title: string) {
      this.itemClicked = true;
      this.dpgToolRedirect(match)
    },
    closeContestants() {
      this.itemClicked = false;
    },
    closeMessage() {
      this.showMessage = !this.showMessage;
    },
  },
  computed: {
    contestantPopupTitle() {
      return [
        this.copy.jouwopstelling.confrim,
        this.copy.jouwwisselspeler.confrim,
        this.copy.jouwmanofthematch.confrim
      ]
    }
  }
});
